import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsStoragePluginModule, SESSION_STORAGE_ENGINE } from '@ngxs/storage-plugin'
import { NgxsModule } from '@ngxs/store'
import { SpOverlayModule } from '@sp-common/saraplus-ngx-common'
import { SaraPlusFormModule } from '@sp-forms/saraplus-ngx-forms'
import { PdfViewerModule } from 'ng2-pdf-viewer'
import { DialogService } from 'primeng/dynamicdialog'
import { environment } from './../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CountdownDialogComponent } from './common/components/countdowndialog/countdowndialog.component'
import { CountdownTimerComponent } from './common/components/countdowntimer/countdown-timer.component'
import { EncryptDecryptComponent } from './common/components/encrypt-decrypt/encrypt-decrypt.component'
import { SpinnerComponent } from './common/components/spinner/spinner.component'
import { AuthHeaderInterceptor } from './common/interceptors/auth-header.interceptor'
import { LoadingIndicatorInterceptor } from './common/interceptors/loading-indicator.interceptor'
import { PayloadEncryptionInterceptor } from './common/interceptors/payload-encryption.interceptor'
import { AppBaseModule } from './common/modules/app-base.module'
import { AppLayoutModule } from './common/modules/app-layout/app-layout.module'
import { PrimeNGModule } from './common/modules/primeng.module'
import { AppRouteReuseStrategy } from './common/services/app-reroute-strategy'
import { CountdownTimerService } from './common/services/countdown-timer.service'
import { AppState } from './common/store/state/app/app.state'
import { UserState } from './common/store/state/user/user.state'
import { AnalyticsModule } from './features/analytics/analytics.module'
import { HomeComponent } from './features/home/home.component'
import { CreateNewPasswordComponent } from './features/identity/forgot-password/create-new-password/create-new-password.component'
import { ForgotPasswordComponent } from './features/identity/forgot-password/forgot-password.component'
import { TemporaryPasscodeComponent } from './features/identity/forgot-password/temporary-passcode/temporary-passcode.component'
import { LoginComponent } from './features/identity/login/login.component'
import { MfaCollectionComponent } from './features/identity/mfa/mfa-collection/mfa-collection.component'
import { MfaSelectionComponent } from './features/identity/mfa/mfa-selection/mfa-selection.component'
import { MfaVerificationComponent } from './features/identity/mfa/mfa-verification/mfa-verification.component'
import { SecurityQaComponent } from './features/identity/security-qa/security-qa.component'
import { WelcomeComponent } from './features/identity/welcome/welcome.component';
import { LoginFlowState } from './common/store/state/login-flow/login-flow.state'
import { HttpErrorInterceptor } from './common/interceptors/http-error.interceptor'
import { MyProfileComponent } from './features/identity/my-profile/my-profile.component';
import { MfaVerificationDialogComponent } from './features/identity/mfa/mfa-verification-dialog/mfa-verification-dialog.component';
import { UserCheckinComponent } from './features/identity/user-checkin/user-checkin.component'
import { OrderState } from './common/store/state/order/order.state'
import { CommonModule } from '@angular/common'
import { ExternalDataService } from './common/services/external-data.service';
import { DataService } from './common/services/data.service';

@NgModule({
  declarations: [
    AppComponent,
    CountdownDialogComponent,
    CountdownTimerComponent,
    EncryptDecryptComponent,
    HomeComponent,
    SpinnerComponent,
    LoginComponent,
    ForgotPasswordComponent,
    TemporaryPasscodeComponent,
    CreateNewPasswordComponent,
    MfaCollectionComponent,
    MfaVerificationComponent,
    MfaSelectionComponent,
    SecurityQaComponent,
    WelcomeComponent,
    MyProfileComponent,
    MfaVerificationDialogComponent,
    UserCheckinComponent
  ],
  imports: [
    AnalyticsModule,
    AppBaseModule,
    AppLayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxsModule.forRoot([UserState, AppState, LoginFlowState, OrderState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [{
        key: UserState,
        engine: SESSION_STORAGE_ENGINE
      }, {
        key: AppState,
        engine: SESSION_STORAGE_ENGINE
      }, {
        key: LoginFlowState,
        engine: SESSION_STORAGE_ENGINE
      },
      {
        key: OrderState,
        engine: SESSION_STORAGE_ENGINE
      }]
    }),
    PrimeNGModule,
    ReactiveFormsModule,
    PdfViewerModule,
    SaraPlusFormModule,
    SpOverlayModule,
    CommonModule
  ],
  providers: [
    ExternalDataService,
    DialogService,
    CountdownTimerService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PayloadEncryptionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingIndicatorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    DataService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
