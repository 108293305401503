import { Component, OnInit, inject } from '@angular/core';
import { BaseComponent } from '../../../common/components/base.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from '../../../common/services/spinner.service';
import { MfaService } from '../../../common/services/mfa/mfa.service';
import { Observable, finalize } from 'rxjs';
import { SecurityQA } from '../../../common/models/Login';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../common/services/auth.service';
@Component({
  selector: 'app-security-qa',
  templateUrl: './security-qa.component.html',
  styleUrl: './security-qa.component.scss'
})
export class SecurityQaComponent extends BaseComponent implements OnInit {
  errorMessage: string
  public confirmed: boolean
  securityQAForm: FormGroup
  lstQuestions$: Observable<SecurityQA[]>
  #spinnerService = inject(SpinnerService)
  #mfaService = inject(MfaService)
  #securityQASpinnerId = 'security-qa-spinner'
  #activatedRoute = inject(ActivatedRoute)
  #router = inject(Router)
  #authService = inject(AuthService)

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.confirmed = (/true/).test(this.#activatedRoute.snapshot.queryParamMap.get('confirmed'))
    this.lstQuestions$ = this.#mfaService.getSecurityQAs()
    this.securityQAForm = new FormGroup({
      question: new FormControl('', [Validators.required]),
      answer: new FormControl('', [Validators.required]),
    })
  }

  onSubmit() {
    this.#spinnerService.setLoading(true, this.#securityQASpinnerId)
    this.subscriptions.add(
      this.#mfaService.validateSecurityQA(this.securityQAForm.value)
        .pipe(finalize(() => {
          this.#spinnerService.setLoading(false, this.#securityQASpinnerId)
        }))
        .subscribe({
          next: () => {
            this.#router.navigate(['/security-qa'],
              { queryParams: { confirmed: true } })
          },
          error: (err) => {
            console.log(err)
            this.errorMessage = 'Please try again'
          }
        })
    )
  }

  onContinueClick() {
    this.subscriptions.add(this.#authService.getWelcomeInfo().subscribe(() => {
      this.#router.navigate(['/welcome']);
    }))
  }
}
