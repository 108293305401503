import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { CollectionType } from '../../enums/mfa.enum'
import { MFAValidateContactModel, MFAValidatePinModel, SecurityQA, SecurityQAReq } from '../../models/Login'
import { SessionTypeEnum } from '../../models/SessionTypeEnum'
import { AppStateService } from '../../store/state/app/app.state.service'
import { UserStateService } from '../../store/state/user/user.state.service'
import { DataService } from '../data.service'
import { EnvironmentService } from '../environment.service'

@Injectable({
  providedIn: 'root'
})
export class MfaService {

  #dataService = inject(DataService)
  #appStateService = inject(AppStateService)
  #userStateService = inject(UserStateService)
  #environmentService = inject(EnvironmentService)
  #validateContact = this.#environmentService.getUrl('ng/v1/user/validatecontact/')
  #validateContactPin = this.#environmentService.getUrl('ng/v1/user/validatecontactpin/')
  #securityQA = this.#environmentService.getUrl('ng/v1/user/securityquestion/')
  #validate = this.#environmentService.getUrl('ng/v1/user/validate/')
  #validatePin = this.#environmentService.getUrl('ng/v1/user/validatepin/')

  validateContact(contactType: CollectionType, value: string): Observable<MFAValidateContactModel> {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const user = this.#userStateService.getUser()
    const payload: MFAValidateContactModel = {
      UserId: user.UserId,
      SessionType: SessionTypeEnum.MFA
    }
    if (CollectionType.email === contactType) {
      payload.ContactEmail = value
    } else {
      payload.MobilePhone = value.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '')
    }

    const endpoint = this.#validateContact + sessionId
    return this.#dataService.post<MFAValidateContactModel, MFAValidateContactModel>(endpoint, payload)
  }

  validateContactPin(contactType: CollectionType, value: string, pin: number, sessionType : SessionTypeEnum): Observable<MFAValidateContactModel> {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const user = this.#userStateService.getUser()
    const payload: MFAValidatePinModel = {
      UserId: user.UserId,
      Pin: pin,
      SessionType: sessionType
    }

    if (CollectionType.email === contactType) {
      payload.ContactEmail = value
    } else if (CollectionType.phone === contactType) {
      payload.MobilePhone = value.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '')
    }

    const endpoint = this.#validateContactPin + sessionId
    return this.#dataService.post<MFAValidatePinModel, MFAValidateContactModel>(endpoint, payload)

  }

  validateSecurityQA(formData: { question: number; answer: string; }): Observable<unknown> {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const user = this.#userStateService.getUser()
    const payload: SecurityQAReq = {
      UserId: user.UserId,
      QuestionId: formData.question,
      QuestionAnswer: formData.answer,
    } as SecurityQAReq

    const endpoint = this.#securityQA + sessionId
    return this.#dataService.put<SecurityQAReq, unknown>(endpoint, payload)

  }

  getSecurityQAs(): Observable<SecurityQA[]> {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const endpoint = this.#securityQA + sessionId
    return this.#dataService.get<SecurityQA[]>(endpoint)

  }

  validate(sessionType: SessionTypeEnum): Observable<MFAValidateContactModel> {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const user = this.#userStateService.getUser()
    const payload: MFAValidateContactModel = {
      UserId: user.UserId,
      SessionType: sessionType
    }

    const endpoint = this.#validate + sessionId
    return this.#dataService.post<MFAValidateContactModel, MFAValidateContactModel>(endpoint, payload)
  }

  validatePin(pin: number, sessionType : SessionTypeEnum): Observable<MFAValidateContactModel> {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const user = this.#userStateService.getUser()
    const payload: MFAValidatePinModel = {
      UserId: user.UserId,
      Pin: pin,
      SessionType: sessionType
    }

    const endpoint = this.#validatePin + sessionId
    return this.#dataService.post<MFAValidatePinModel, MFAValidateContactModel>(endpoint, payload)

  }

}
