import { Component, inject } from '@angular/core'
import { BaseComponent } from './common/components/base.component'
import { SpinnerService } from './common/services/spinner.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent extends BaseComponent {
  showLoader = false
  #spinnerService = inject(SpinnerService)

  constructor() {
    super()
    this.#spinnerService.loadingSub.subscribe({
      next: (value) => {
        this.showLoader = value
      },
    })
  }

}