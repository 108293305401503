<app-unauthorized-header-layout header="Two-Factor Authentication" subHeader="SARA Plus requires users to validate their device every 30 days." subHeader2="Please select where you want to receive the verification code."></app-unauthorized-header-layout>
@if (errorMessage) {
<div class="flex p-error justify-content-center mt-4 mb-2 text-xl">{{errorMessage}}</div>
}
<div class="flex justify-content-center flex-wrap w-full mt-3">
    <div class="surface-card p-4 w-saraplus-form border-round w-full sm:w-7 md:w-5 lg:3">
        <form class="flex flex-column gap-3" [formGroup]="selectionForm" (submit)="onContinue()">
            <div *ngFor="let type of devicesTypes" class="field-checkbox">
                <div class="card flex flex-1 justify-content-center">
                    <div class="sara-card flex-1">
                        <p-radioButton [inputId]="type" [value]="type" formControlName="selectedDevice"></p-radioButton>
                        <label [for]="type" class="ml-2 label-regular">{{ type }}</label>
                        <p class="ml-4 text-sm font-light text-color-secondary my-0 pl-1">Receive verification code via
                            {{type === 'Phone' ? 'mobile device' : 'email'}}</p>
                    </div>
                </div>
            </div>
            <div class="flex justify-content-end mb-5">
                <button label="CONTINUE" pButton pRipple type="submit" [disabled]="!selectionForm.valid" size="small">
                </button>
            </div>
        </form>
    </div>
</div>