import { Component, OnInit, inject } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { finalize } from 'rxjs'
import { BaseComponent } from '../../../../common/components/base.component'
import { AuthService } from '../../../../common/services/auth.service'
import { SpinnerService } from '../../../../common/services/spinner.service'
import { UserStateService } from '../../../../common/store/state/user/user.state.service'
import { NotificationService } from '../../../../common/services/notification.service'
import { Message } from '../../../../common/enums/message.enum'
import { HttpErrorResponse } from '@angular/common/http'

@Component({
  selector: 'app-temporary-passcode',
  templateUrl: './temporary-passcode.component.html',
  styleUrl: './temporary-passcode.component.scss'
})
export class TemporaryPasscodeComponent extends BaseComponent implements OnInit  {

  #userStateService = inject(UserStateService)
  #authService = inject(AuthService)
  #router = inject(Router)
  #spinnerService = inject(SpinnerService)
  #notificationService = inject(NotificationService)

  passcodeForm: FormGroup
  errorMessage: string

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.passcodeForm = new FormGroup({
      pass: new FormControl('', [Validators.required]),
    })
  }


  async onValidate() {
    this.#spinnerService.setLoading(true, 'passcode')
    this.subscriptions.add(this.#authService.validatePasscode(this.passcodeForm.value)
    .pipe(finalize(() => {
      this.#spinnerService.setLoading(false, 'passcode')
    }))
    .subscribe({
      next: () => this.#router.navigate(['/forgot-password/create-new']),
      error: (err: HttpErrorResponse) => {
        this.errorMessage = err?.status  === 400 ? 'Please try again' :  err.error
        console.log(err)
      }
    }))
  }

  sendPin() {
    this.subscriptions.add(this.#authService.forgotPassword({ email: this.#userStateService.getUnauthorizedUser()?.Email }).subscribe(
      {
        next: () => {
          this.#notificationService.showSuccess(Message.PinResentSuccess)
        },
        error: () => {
          this.errorMessage = 'Invalid Email or Phone'
        }
      }
    ))
  }
}
