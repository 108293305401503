import { Component, OnInit, inject } from '@angular/core';
import { UserService } from '../../../common/services/user.service';
import { UserClockInResponse } from '../../../common/models/UserId';
import { take } from 'rxjs';
import { BaseComponent } from '../../../common/components/base.component';

@Component({
  selector: 'app-user-checkin',
  templateUrl: './user-checkin.component.html',
  styleUrl: './user-checkin.component.scss'
})
export class UserCheckinComponent extends BaseComponent implements OnInit {

  #userService = inject(UserService)
  today = new Date();
  isCheckedIn = true;
  clockInTimes: UserClockInResponse[] = [];

  ngOnInit(): void {
    this.getClockinTimes();
  }
  getClockinTimes() {
    this.#userService.clockinTimes().pipe(take(1)).subscribe({
      next: (val) => {
        this.clockInTimes = val;
        this.isCheckedIn = (this.clockInTimes.length > 0 && this.clockInTimes[this.clockInTimes.length - 1].ClockOut == undefined);
      },
      error: () => {
        this.isCheckedIn = false;
      }
    })
  }

  onCheckinClicked() {
    if (this.isCheckedIn) {
      this.#userService.checkout().pipe(take(1)).subscribe({
        next: () => {
          this.getClockinTimes();
        }
      });
    } else {
      this.#userService.checkin().pipe(take(1)).subscribe({
        next: () => {
          this.getClockinTimes();
        }
      });
    }
  }

  onCancelClicked() {

  }
}
