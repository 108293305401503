import { Component, OnInit, inject } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BaseComponent } from '../../../../common/components/base.component'
import { finalize } from 'rxjs'
import { AuthService } from '../../../../common/services/auth.service'
import { SpinnerService } from '../../../../common/services/spinner.service'
import { PasswordService } from '../../../../common/services/password/password.service'

@Component({
  selector: 'app-create-new-password',
  templateUrl: './create-new-password.component.html',
  styleUrl: './create-new-password.component.scss'
})
export class CreateNewPasswordComponent extends BaseComponent implements OnInit {

  isForceReset = false

  subheader1 = 'For security reasons, SARA Plus requires you to reset your password every 90 days.'
  subheader2 = 'Enter a new password below'

  #authService = inject(AuthService)
  #router = inject(Router)
  #activatedRoute = inject(ActivatedRoute)
  #spinnerService = inject(SpinnerService)
  #passwordService = inject(PasswordService)

  public confirmed: boolean

  public passwordForm: FormGroup

  constructor() {
    super()
  }

  ngOnInit(): void {
    // check the url contains reset
    this.isForceReset = this.#activatedRoute.snapshot.data['forceReset']
    this.confirmed = (/true/).test(this.#activatedRoute.snapshot.queryParamMap.get('confirmed'))
    this.passwordForm = this.#passwordService.initForm()
  }

  async onSave() {
    this.#spinnerService.setLoading(true, 'create-new')
    this.subscriptions.add(this.#authService.createPassword(this.passwordForm.value)
      .pipe(finalize(() => {
        this.#spinnerService.setLoading(false, 'create-new')
      }))
      .subscribe({
        next: () => this.#router.navigate(['/forgot-password/create-new'],
          { queryParams: { confirmed: true } }),
        error: (err) => console.log(err)
      }))
  }

  get passValue() {
    return this.passwordForm.controls['pass'].value
  }

  isValidCharacter(passValue: string): boolean {
    const pattern = /([|'"\\])/;
    return !pattern.test(passValue);
  }
}
