import { Injectable } from '@angular/core'
import { Location } from '@angular/common'

import { Router } from '@angular/router'

const EMPTY_HISTORY_LENGTH = 2

/**
 * This service helps to Navigate back to the prev page, and if no prev page,
 * will redirect to the fallback url.
 */

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(private router: Router, private location: Location) { }

  /**
   * This method will back you to the previous page,
   * if no previous page exists, will redirect you to the fallback url.
   * @param href - url, if tryNativeFirst is provided, this is fallback url
   * @param tryNativeFirst - try to go back natively using browser history state.
   */
  back(href: string, tryNativeFirst: boolean = false) {
    if (tryNativeFirst) {
      if (history.length === EMPTY_HISTORY_LENGTH) {
        this.router.navigate(RouteService.urlToArray(href))
      } else {
        this.location.back()
      }
    } else {
      this.router.navigate(RouteService.urlToArray(href))
    }
  }

  /**
   * In case that router.navigate method tries to escape all '/' in the string,
   * was decided to split string to array, and if URL starts or ends with slash - remove them, eg:
   * /my/url will be split to ['', 'my', 'url'], so we need to remove empty spaces use filter function.
   * @param href
   * @private
   */
  private static urlToArray(href: string) {
    return href.split('/').filter((notEmpty) => notEmpty)
  }
}