<div class="py-1 px-1 md:py-2 md:px-5 text-center">
    <div class="py-4 checkin-container">
        <div class="flex flex-column gap-4">
            <div class="flex-1 mb-0">
                <span class="checkin-header text-2xl md:text-4xl">Time Entry</span>
            </div>
            <div class="flex-1">
                <span class="m-0 text-xl md:text-2xl font-bold">
                    {{ today | date : 'mediumDate' }}
                </span>
            </div>
            @if (clockInTimes.length > 0) {
            <div class="flex-1 text-xs">
                <div class="flex flex-row gap-2 justify-content-center font-semibold mb-1 md:mb-2">
                    <span class="clockin-header md:w-2 w-2 py-1">Login</span>
                    <span class="clockin-header md:w-2 w-2 py-1">Logout</span>
                </div>

                @for (item of clockInTimes; track $index) {
                <div class="flex flex-row gap-2 justify-content-center font-semibold mb-2">
                    <span class="md:w-2 clockin-time w-2 py-1"> {{ item.ClockIn | date : 'shortTime' }}</span>
                    <span class="md:w-2 clockin-time w-2 py-1"> {{ item.ClockOut| date : 'shortTime'
                        }}</span>
                </div>
                }
            </div>
            }
            <div class="flex-1 checked text-xl">
                <span class="m-0" [ngClass]="{'checked-out' : !isCheckedIn, 'checked-in' : isCheckedIn}">
                    {{ isCheckedIn ? 'You are currently checked in' : 'You are currently checked out'}}
                </span>
            </div>
            <div class="flex-1">
                <div class="flex justify-content-center">
                    <div class="md:col-6 px-4 w-full md:w-10">
                        <button class="p-button-rounded w-full" [label]="!isCheckedIn ? 'CHECK IN' : 'CHECK OUT'"
                            pButton pRipple type="button" (click)="onCheckinClicked()">
                        </button>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</div>