import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { DataService } from './data.service';
import { AppStateService } from '../store/state/app/app.state.service';
import { UserClockInResponse } from '../models/UserId';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  #environmentService = inject(EnvironmentService)
  #dataService = inject(DataService)
  #appStateService = inject(AppStateService)

  #checkinUrl = this.#environmentService.getUrl('ng/v1/user/clockin/')
  #checkoutUrl = this.#environmentService.getUrl('ng/v1/user/clockout/')
  #clockInTimesUrl = this.#environmentService.getUrl('ng/v1/user/clockintimes/')

  checkin() {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const endpoint = this.#checkinUrl + sessionId
    return this.#dataService.post(endpoint, null);
  }

  checkout() {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const endpoint = this.#checkoutUrl + sessionId
    return this.#dataService.post(endpoint, null);
  }

  clockinTimes(): Observable<UserClockInResponse[]> {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const endpoint = this.#clockInTimesUrl + sessionId
    return this.#dataService.get<UserClockInResponse[]>(endpoint);
  }
}
