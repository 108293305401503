<app-unauthorized-header-layout header="Please enter the temporary passcode sent to <br> your email or phone number."></app-unauthorized-header-layout>
<div class="text-color-secondary text-center">
    <span class="font-light text-sm">The passcode is valid for 10 minutes.</span>
</div>
@if (errorMessage) {
<div class="flex p-error justify-content-center mt-4 mb-2 text-xl">{{errorMessage}}</div>
}
<div class="flex justify-content-center flex-wrap w-full mt-3">
    <div class="surface-card w-saraplus-form border-round">
        <sp-form-wrapper [formGroup]="passcodeForm" (submit)="onValidate()">
            <div class="flex flex-column align-items-center">
                <div>
                    <div class="mb-3">
                        <p-inputOtp formControlName="pass" [integerOnly]="true" [length]="6"
                        [autoFocus]="true"></p-inputOtp>
                        <sp-control-error [parameters]="['Pin']" [control]="passcodeForm.controls['pass']"></sp-control-error>
                    </div>
                    <div class="flex justify-content-between mb-12rem">
                        <p-button icon="pi pi-chevron-left" label="Back to Login" [link]="true"
                            styleClass="outline-none font-italic text-lg -ml-3" [routerLink]="'/login'"></p-button>
                        <button label="VALIDATE" pButton pRipple type="submit" [disabled]="!passcodeForm.valid"
                            size="small">
                        </button>
                    </div>
                </div>
            </div>
        </sp-form-wrapper>
    </div>
</div>
<div class="flex flex-column align-items-center w-full h-8rem">
    <p-button label="Send another code" icon="pi pi-refresh" iconPos="right" [link]="true" styleClass="outline-none text-sm" (click)="sendPin()"></p-button>
    <p-button label="Still having trouble? Click here for help" [link]="true"
        styleClass="outline-none text-sm"></p-button>
</div>