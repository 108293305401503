import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from "@angular/router"

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {

    public override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (future.data['skipReuse']) {
            return false
        }
        return (future.routeConfig === curr.routeConfig)
    }
}