import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { RolesService } from "../services/roles.service";

export const RoleAuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot
): boolean => {
    const rolesService: RolesService = inject(RolesService)
    const userModules = rolesService.getUserModules();
    let canAccess = false;
    if (route.data && route.data.userModules && (route.data.userModules as string[]).length > 0) {
        for (let index = 0; index < userModules.length; index++) {
            const element = userModules[index];
            const hasAccess = (route.data.userModules as string[]).find(x => x.toLowerCase() === element.toLowerCase());
            if (hasAccess) {
                canAccess = true;
                break;
            }
        }
    } else {
        canAccess = true;
    }
    return canAccess;
};

export const RoleAuthGuardActivateChild = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => RoleAuthGuard(route, state);