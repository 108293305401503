<app-unauthorized-header-layout [header]="passwordLabel"></app-unauthorized-header-layout>

@if (errorMessage) {
<div class="flex p-error justify-content-center my-1 text-xl">{{errorMessage}}</div>
}
<div class="flex justify-content-center flex-wrap w-full mt-3">
    <div class="surface-card p-4 w-saraplus-form border-round w-full sm:w-7 md:w-5 lg:4">
        <div>
            <sp-form-wrapper [formGroup]="forgotPasswordForm" (submit)="onContinue()">
                <div class="flex flex-column">
                    <span class="mb-4">
                        <input type="text" placeholder="Enter login email" pInputText formControlName="email"
                            class="w-full mb-1" />
                        <sp-control-error [parameters]="['Email']"
                            [control]="forgotPasswordForm.controls['email']"></sp-control-error>
                    </span>
                    <div class="flex justify-content-between mb-5">
                        <p-button icon="pi pi-chevron-left left-arrow" label="Back to Login" [link]="true"
                            styleClass="outline-none font-italic text-lg -ml-3" [routerLink]="'/login'"></p-button>
                        <button label="CONTINUE" pButton pRipple type="submit" [disabled]="!forgotPasswordForm.valid"
                            size="small">
                        </button>
                    </div>
                </div>
            </sp-form-wrapper>
        </div>
    </div>
</div>