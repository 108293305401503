<app-unauthorized-header-layout header="Sign In" [headerCustomClass]="'login-header'"></app-unauthorized-header-layout>

@if (invalidLogin) {
<div class="flex p-error justify-content-center mb-5 text-2xl">{{invalidLogin}}</div>
}
<div class="flex justify-content-center flex-wrap  w-full">
    <div class="surface-card px-4 pb-4 w-saraplus-form border-round w-full sm:w-7 md:w-6 lg:4">
        <div>
            <sp-form-wrapper [formGroup]="loginForm" (submit)="login()">
                <div class="flex flex-column">
                    <span class="mb-3">
                        <input type="text" placeholder="Enter email" pInputText formControlName="email"
                            class="w-full mb-1" />
                        <sp-control-error [parameters]="['Email']"
                            [control]="loginForm.controls['email']"></sp-control-error>
                    </span>
                    <span class="mb-4">
                        <p-password placeholder="Enter password" formControlName="pass" [toggleMask]="true"
                            styleClass="w-full" inputStyleClass="w-full mb-1" [feedback]="false"></p-password>
                        <sp-control-error [parameters]="['Password']"
                            [control]="loginForm.controls['pass']"></sp-control-error>
                    </span>
                    <div class="flex justify-content-between mb-5">
                        <p-button label="Forgot Password?" [link]="true"
                            styleClass="underline outline-none font-italic text-lg -ml-3"
                            [routerLink]="'/forgot-password'"></p-button>
                        <button label="LOG IN" pButton pRipple type="submit" [disabled]="!loginForm.valid" size="small">
                        </button>
                    </div>
                </div>
            </sp-form-wrapper>
        </div>
    </div>
</div>