export { }
declare global {
    interface String {
        Mask(type: 'phone' | 'email'): string;
    }
}

String.prototype.Mask = function (type: 'phone' | 'email'): string {
    if (this) {
        if (type == "phone" && this.length > 4) {
            return `(***)***-${this.substring((this.length - 4), (this.length))}`
        } else {
            return `*****${this.substring(5, (this.length))}`
        }
    }
    return this as string;
}