<div style="margin: -1.5rem 0;">
    <div class="relative overflow-hidden">
        <div class="bg relative">
            <img alt="Sara Plus" class="absolute" src="assets/images/bg/Home-bg.jpg" height="600px" width="100%">
        </div>
        <div class="text-center relative mt-6 p-6">
            <div>
                <div class="text-6xl text-white font-bold mb-1">SARA Plus™</div>
                <div class="text-6xl text-green-100 font-bold mb-4">One Solution for All Your Business Needs</div>
                <p class="mt-0 mb-4 line-height-3 text-center mx-auto text-white" style="max-width: 500px;">
                    The one and only application you need to run your business efficiently and successfully.
                </p>
                <button pbutton="" pripple="" label="Learn More" type="button"
                    class="p-element p-ripple p-button p-component">
                    <span class="p-button-label">Learn More</span>
                    <span class="p-ink"></span>
                </button>
            </div>
        </div>
    </div>
    <div class="surface-section px-2 lg:px-3 pt-8 text-center">
        <div class="mb-3 font-bold text-3xl"><span class="text-900">One Product, </span><span class="text-blue-600">Many
                Solutions</span></div>
        <div class="text-700 mb-6">SARA Plus™ manages your business by giving the dealer the opportunity to streamline
            their business.</div>
        <div class="grid">
            <div class="col-12 md:col-6 lg:col-4 xl:col-3 mb-4 px-5"><span
                    class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px;"><i
                        class="pi pi-file-edit text-4xl text-blue-500"></i></span>
                <div class="text-900 text-xl mb-3 font-medium">Order Entry</div><span class="text-700 line-height-3">An
                    easier way of entering in your order. Whether it be ordering AT&T/DIRECTV, or other services in the
                    area, SARA Plus™ makes this an easier process for both you and your customer.</span>
            </div>
            <div class="col-12 md:col-6 lg:col-4 xl:col-3 mb-4 px-5"><span
                    class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px;"><i
                        class="pi pi-briefcase text-4xl text-blue-500"></i></span>
                <div class="text-900 text-xl mb-3 font-medium">Business Management</div><span
                    class="text-700 line-height-3">SARA Plus™ makes it easier for the dealers to manage their business
                    by providing the easiest way to monitor their funds.</span>
            </div>
            <div class="col-12 md:col-6 lg:col-4 xl:col-3 mb-4 px-5"><span
                    class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px;"><i
                        class="pi pi-shopping-bag text-4xl text-blue-500"></i></span>
                <div class="text-900 text-xl mb-3 font-medium">Inventory Tracking</div><span
                    class="text-700 line-height-3">Keeping track of the inventory has never been easier! Manage all
                    equipment, including in-house stock and on your installers' trucks, from any computer.</span>
            </div>
            <div class="col-12 md:col-6 lg:col-12 xl:col-3 mb-4 px-5"><span
                    class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px;"><i
                        class="pi pi-calendar-times text-4xl text-blue-500"></i></span>
                <div class="text-900 text-xl mb-3 font-medium">Scheduling</div><span
                    class="text-700 line-height-3">Order entry and scheduling has never been easier. SARA Plus™ offers
                    an easy way to get an installer set-up and planned without the messy paperwork.</span>
            </div>
        </div>
    </div>
    <div class="surface-ground px-4 py-4 md:px-6 lg:px-8">
        <div class="font-bold text-5xl text-900 mb-5 text-center">SARA Plus™</div>
        <div class="grid nogutter">
            <div class="col-12 lg:col-6 p-3">
                <p-card header="PRICING" [styleClass]="'h-full'">
                    <ng-template pTemplate="header">
                        <div class="bg-black-alpha-50">
                            <img src="assets/images/bg/SARA_pricing_icon.png" alt="Sara Plus Pricing"
                                class="block w-full border-round-top" />
                        </div>
                    </ng-template>
                    <p>
                        Contact your DSI Sales Rep or at support&#64;saraplus.com.
                        <br />
                        &nbsp;
                    </p>
                    <ng-template pTemplate="footer">
                        <div class="flex justify-content-center">
                            <p-button label="Learn More" icon="pi pi-dollar"></p-button>
                        </div>
                    </ng-template>
                </p-card>
            </div>
            <div class="col-12 lg:col-6 p-3">
                <p-card header="FREE & PREMIUM VERSIONS">
                    <ng-template pTemplate="header">
                        <div class="bg-black-alpha-50">
                            <img src="assets/images/bg/SARA_compare_icon.png" alt="Sara Plus Version"
                                class="block w-full border-round-top">
                        </div>
                    </ng-template>
                    <p>
                        View our camparison chart to see
                        what features are offered between our FREE and PREMIUM version to choose what is best for
                        your business.
                    </p>
                    <ng-template pTemplate="footer">
                        <div class="flex justify-content-center">
                            <p-button label="Learn More" icon="pi pi-check-square"></p-button>
                        </div>
                    </ng-template>
                </p-card>
            </div>
        </div>
    </div>
    <div class="flex justify-content-center p-4 surface-ground">
        <button pbutton="" pripple="" label="Register" class="p-button p-button-lg p-button-warning p-component p-element p-ripple">
            <span class="p-button-label">SIGN UP FOR SARA Plus™</span>
            <span class="p-ink"></span>
        </button>
    </div>
</div>