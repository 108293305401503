<ng-container *ngIf="!confirmed else createConfirmed">
    <app-unauthorized-header-layout header="Create New Password" [subHeader]="isForceReset ? subheader1 : ''"
    [subHeader2]="isForceReset ? subheader2 : ''"></app-unauthorized-header-layout>
    <div class="flex justify-content-center flex-wrap w-full">
        <div class="surface-card p-4 w-saraplus-form border-round w-full sm:w-7 md:w-5 lg:3">
            <div>
                <sp-form-wrapper [formGroup]="passwordForm" (submit)="onSave()">
                    <div class="flex flex-column">
                        <span class="mb-3">
                            <p-password placeholder="Enter password" formControlName="pass" [toggleMask]="true"
                                styleClass="w-full" inputStyleClass="w-full mb-1"></p-password>
                            <sp-control-error [parameters]="['Password']"
                                [control]="passwordForm.controls['pass']"></sp-control-error>
                        </span>
                        <span class="mb-4">
                            <p-password placeholder="Confirm password" formControlName="repass" [feedback]="false"
                                [toggleMask]="true" styleClass="w-full" inputStyleClass="w-full mb-1"></p-password>
                            <sp-control-error [parameters]="['Password']"
                                [control]="passwordForm.controls['repass']"></sp-control-error>
                        </span>
                        <p class="mt-0 ml-2 p-error" *ngIf="passwordForm?.errors">Passwords must match</p>
                        <div class="flex justify-content-between mb-5">
                            <div class="text-sm">
                                <ng-container
                                    *ngTemplateOutlet="charChecker; context : { matched: passValue?.match('^.{8,32}$'), label: 'must be 8-32 characters' }"></ng-container>
                                <ng-container
                                    *ngTemplateOutlet="charChecker; context : { matched: passValue?.match('(?=.*[A-Z])'), label: '1 uppercase letter' }"></ng-container>
                                <ng-container
                                    *ngTemplateOutlet="charChecker; context : { matched: passValue?.match('(?=.*[a-z])'), label: '1 lowercase letter' }"></ng-container>
                                <ng-container
                                    *ngTemplateOutlet="charChecker; context : { matched: passValue?.match('(.*[0-9].*)'), label: '1 number' }"></ng-container>
                                <ng-container
                                    *ngTemplateOutlet="charChecker; context : { matched: passValue?.match('(?=.*[!#$%&()*+,-.:;<=>?@[\\\]^_{}~])'), label: '1 special character' }"></ng-container>
                                <ng-container
                                    *ngTemplateOutlet="charChecker; context : { matched: !passValue?.match('([\\/|\'&quot;\\\\])'), label: 'No invalid characters (&quot; \/ | \\ \')' }"></ng-container>
                            </div>
                            <div>
                                <button label="SAVE" pButton pRipple type="submit" [disabled]="!passwordForm.valid || !isValidCharacter(passValue)"
                                    size="small">
                                </button>
                            </div>

                        </div>
                    </div>
                </sp-form-wrapper>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #createConfirmed>
    <app-unauthorized-header-layout header="Password was successfully changed!"></app-unauthorized-header-layout>
    <div class="text-center">
        <p-button label="Back to login"  icon="pi pi-chevron-right" iconPos="right" [link]="true" styleClass="outline-none text-md ml-3"
            [routerLink]="'/login'"></p-button>
    </div>
</ng-template>

<ng-template #charChecker let-matched="matched" let-label="label">
    <span class="block">
        @if (matched) {
        <i class="pi pi-check" [style.color]="'green'"></i>
        } @else {
        <i class="pi pi-times-circle" [style.color]="'red'"></i>
        }
        {{label}}
    </span>
</ng-template>