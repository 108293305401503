<app-unauthorized-header-layout subHeaderCustomClass="font-normal text-xl" header="Two-Factor Authentication" [headerCustomClass]="'mb-5 mt-0'"
    [subHeader]="'To unlock this page and edit your profile information, please enter the six digit code sent to your email or phone'"></app-unauthorized-header-layout>
<div class="text-color-secondary text-center">
    <span class="font-light text-sm">The verification code is valid for 10 minutes
    </span>
</div>
@if (errorMessage) {
<div class="flex p-error justify-content-center mt-4 mb-2 text-xl">{{errorMessage}}</div>
}
<div class="flex justify-content-center flex-wrap w-full mt-3">
    <div class="surface-card w-saraplus-form border-round w-full sm:w-7 md:w-5 lg:4">
        <sp-form-wrapper [formGroup]="pinForm" (submit)="onValidate()">
            <div class="flex flex-column align-items-center">
                <div>
                    <div class="mb-3">
                        <p-inputOtp [autofocus]="true" formControlName="pin" [tabindex]="1" [integerOnly]="true" [length]="6"
                             class="w-full mb-1 justify-content-between"></p-inputOtp>
                        <sp-control-error [parameters]="['Code']" [control]="pinForm.controls['pin']"></sp-control-error>
                    </div>
                    <div class="flex justify-content-center mb-3">
                        <button label="VALIDATE" pButton pRipple type="submit" class="p-button-rounded" [disabled]="!pinForm.valid" size="small">
                        </button>
                    </div>
                </div>
            </div>
        </sp-form-wrapper>
    </div>
</div>
<div class="flex flex-column align-items-center w-full">
    <p-button label="Send another code" icon="pi pi-refresh" iconPos="right" [link]="true" styleClass="outline-none text-sm" (click)="sendPin()"></p-button>
</div>