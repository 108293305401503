import { Injectable, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionTypeEnum } from '../../models/SessionTypeEnum';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { CreatePasswordReq } from '../../models/CreatePassword';
import { EnvironmentService } from '../environment.service';
import { UserStateService } from '../../store/state/user/user.state.service';
import { AppStateService } from '../../store/state/app/app.state.service';
import { DataService } from '../data.service';

const StrongPasswordRegx: RegExp =
  /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,32}$/

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  #environmentService = inject(EnvironmentService)
  #appStateService = inject(AppStateService)
  #userStateService = inject(UserStateService)
  #dataService = inject(DataService)

  #updatePassword = this.#environmentService.getUrl('ng/v1/user/update/')

  constructor() { }

  initForm () {
    const passwordForm =  new FormGroup({
      pass: new FormControl('', [Validators.required, Validators.pattern(StrongPasswordRegx)]),
      repass: new FormControl('', [Validators.required]),
    })
    passwordForm.addValidators(
      this.#createCompareValidator(
        passwordForm.get('pass') as AbstractControl,
        passwordForm.get('repass') as AbstractControl
      )
    )
    return passwordForm
  }

  #createCompareValidator(controlOne: AbstractControl, controlTwo: AbstractControl) {
    return () => {
      if (controlOne.value && controlTwo.value && controlOne.value !== controlTwo.value)
        return { match_error: true }
      return null
    }
  }


  updatePassword(formData: { pass: string, currentpass: string }, SessionType = SessionTypeEnum.RESET_PASSWORD): Observable<unknown> {
    const sessionId = this.#appStateService.getSession()?.SessionID
    const userId = this.#userStateService.getUnauthorizedUser()?.UserId

    const payload: CreatePasswordReq = {
      UserId: userId,
      NewPassword: formData.pass,
      CurrentPassword: formData.currentpass,
      SessionType: SessionType
    }

    const endpoint = this.#updatePassword + sessionId

    const reqHeaders = new HttpHeaders().set('Content-Type', 'application/json')
    return this.#dataService.put<CreatePasswordReq, unknown>(endpoint, payload, reqHeaders)
  }

  

}
