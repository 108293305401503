import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, ViewChild, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
const _c0 = ["mask"];
const _c1 = ["*"];
const _c2 = a0 => ({
  "p-blockui-document": a0,
  "p-blockui p-component-overlay p-component-overlay-enter": true
});
const _c3 = () => ({
  display: "none"
});
class DomHandler {
  static addClass(element, className) {
    if (element && className) {
      if (element.classList) element.classList.add(className);else element.className += ' ' + className;
    }
  }
  static blockBodyScroll(className = 'p-overflow-hidden') {
    document.body.style.setProperty('--scrollbar-width', this.calculateBodyScrollbarWidth() + 'px');
    this.addClass(document.body, className);
  }
  static calculateBodyScrollbarWidth() {
    return window.innerWidth - document.documentElement.offsetWidth;
  }
  static unblockBodyScroll(className = 'p-overflow-hidden') {
    document.body.style.removeProperty('--scrollbar-width');
    this.removeClass(document.body, className);
  }
  static removeClass(element, className) {
    if (element && className) {
      if (element.classList) element.classList.remove(className);else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }
}
class SpOverlayComponent {
  get blocked() {
    return this._blocked;
  }
  set blocked(val) {
    if (this.mask && this.mask.nativeElement) {
      if (val) this.block();else this.unblock();
    } else {
      this._blocked = val;
    }
  }
  constructor(document, el, cd, renderer, platformId) {
    this.document = document;
    this.el = el;
    this.cd = cd;
    this.renderer = renderer;
    this.platformId = platformId;
    this._blocked = false;
  }
  block() {
    this._blocked = true;
    this.mask.nativeElement.style.display = 'flex';
    if (this.target) {
      this.target.appendChild(this.mask.nativeElement);
      this.target.style.position = 'relative';
    } else {
      this.renderer.appendChild(this.document.body, this.mask.nativeElement);
      DomHandler.blockBodyScroll();
    }
  }
  unblock() {
    if (this.mask && !this.animationEndListener) {
      this.animationEndListener = this.renderer.listen(this.mask.nativeElement, 'animationend', this.destroyModal.bind(this));
      DomHandler.addClass(this.mask.nativeElement, 'p-component-overlay-leave');
    }
  }
  destroyModal() {
    this._blocked = false;
    if (this.mask) {
      DomHandler.removeClass(this.mask.nativeElement, 'p-component-overlay-leave');
      this.renderer.removeChild(this.el.nativeElement, this.mask.nativeElement);
      DomHandler.unblockBodyScroll();
    }
    this.unbindAnimationEndListener();
    this.cd.markForCheck();
  }
  unbindAnimationEndListener() {
    if (this.animationEndListener && this.mask) {
      this.animationEndListener();
      this.animationEndListener = null;
    }
  }
  ngAfterViewInit() {
    if (this._blocked) this.block();
  }
  ngAfterContentInit() {}
  ngOnDestroy() {
    this.unblock();
    this.destroyModal();
  }
  static {
    this.ɵfac = function SpOverlayComponent_Factory(t) {
      return new (t || SpOverlayComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(PLATFORM_ID));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SpOverlayComponent,
      selectors: [["sp-overlay"]],
      viewQuery: function SpOverlayComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.mask = _t.first);
        }
      },
      inputs: {
        target: "target",
        styleClass: "styleClass",
        blocked: "blocked"
      },
      ngContentSelectors: _c1,
      decls: 3,
      vars: 10,
      consts: [["mask", ""], [3, "ngClass", "ngStyle"]],
      template: function SpOverlayComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 1, 0);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.styleClass);
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(7, _c2, !ctx.target))("ngStyle", i0.ɵɵpureFunction0(9, _c3));
          i0.ɵɵattribute("aria-busy", ctx.blocked)("data-pc-name", "blockui")("data-pc-section", "root");
        }
      },
      dependencies: [i1.NgClass, i1.NgStyle],
      styles: ["@layer saraplus{.p-blockui[_ngcontent-%COMP%]{position:absolute;top:0;left:0;width:100%;height:100%;background-color:transparent;transition-property:background-color;display:flex;align-items:center;justify-content:center}.p-blockui.p-component-overlay[_ngcontent-%COMP%]{position:absolute}.p-blockui-document.p-component-overlay[_ngcontent-%COMP%]{position:fixed}.p-blockui-leave.p-component-overlay[_ngcontent-%COMP%]{background-color:transparent}}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpOverlayComponent, [{
    type: Component,
    args: [{
      selector: 'sp-overlay',
      template: `
  <div #mask 
            [class]="styleClass"
            [attr.aria-busy]="blocked"
            [ngClass]="{ 'p-blockui-document': !target, 'p-blockui p-component-overlay p-component-overlay-enter': true }"
            [ngStyle]="{ display: 'none' }"
            [attr.data-pc-name]="'blockui'"
            [attr.data-pc-section]="'root'">
    <ng-content></ng-content>
  </div>
  `,
      styles: ["@layer saraplus{.p-blockui{position:absolute;top:0;left:0;width:100%;height:100%;background-color:transparent;transition-property:background-color;display:flex;align-items:center;justify-content:center}.p-blockui.p-component-overlay{position:absolute}.p-blockui-document.p-component-overlay{position:fixed}.p-blockui-leave.p-component-overlay{background-color:transparent}}\n"]
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i0.Renderer2
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], {
    target: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    blocked: [{
      type: Input
    }],
    mask: [{
      type: ViewChild,
      args: ['mask']
    }]
  });
})();
class SpOverlayModule {
  static {
    this.ɵfac = function SpOverlayModule_Factory(t) {
      return new (t || SpOverlayModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SpOverlayModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpOverlayModule, [{
    type: NgModule,
    args: [{
      declarations: [SpOverlayComponent],
      imports: [CommonModule],
      exports: [SpOverlayComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of saraplus-ngx-common
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DomHandler, SpOverlayComponent, SpOverlayModule };
