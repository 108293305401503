import { inject } from '@angular/core'
import { ResolveFn, Router } from '@angular/router'
import { AppStateService } from '../store/state/app/app.state.service'
import { UserStateService } from '../store/state/user/user.state.service'
import { UserDetails } from '../models/Login'

const skipRoutes = ['login', 'forgot-password', 'reset-password', 'edc', 'mfa', 'security-qa']

export const deviceResolver: ResolveFn<boolean> = async (route) => {
  const appStateService = inject(AppStateService)
  const userStateService = inject(UserStateService)
  const router = inject(Router)

  // console.log('route', route.routeConfig?.path)
  if (route.routeConfig && skipRoutes.includes(route.routeConfig.path as string)) {
    return true
  }
  if (!appStateService.getDevice() || !userStateService.getIsLoggedIn()) {
    userStateService.setUser({} as UserDetails)
    userStateService.setAuthorized(false)
    router.navigate(['login'])
    return false
  }
  return true
}
