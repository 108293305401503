import { Component, effect, inject } from '@angular/core'
import { AppStateService } from '../../store/state/app/app.state.service'
import { Message } from './../../enums/message.enum'
import { EncryptDecryptService } from './../../services/encrypt-decrypt.service'
import { NotificationService } from './../../services/notification.service'

@Component({
  selector: 'app-encrypt-decrypt',
  templateUrl: './encrypt-decrypt.component.html'
})
export class EncryptDecryptComponent {

  aesKey: string
  token: string
  sessionId: string

  #appStateService = inject(AppStateService)
  #notificationService = inject(NotificationService)
  #encryptDecryptService = inject(EncryptDecryptService)

  constructor() {
    effect(() => {
      this.aesKey = this.#appStateService.getSession()?.ClientAesKey
      this.token = this.#appStateService.getToken()?.access_token
      this.sessionId = this.#appStateService.getSession()?.SessionID
    })
  }

  encryptionInputValue = ''
  decryptionInputValue = ''
  encryptionResultValue = ''
  decryptionResultValue = ''

  encryptData() {
    this.encryptionResultValue = this.#encryptDecryptService.encrypt(this.encryptionInputValue, this.aesKey)
  }
  decryptData() {
    this.decryptionResultValue = this.#encryptDecryptService.decrypt(this.decryptionInputValue, this.aesKey) as string
  }

  copyToClipboard(type: string) {
    if (type === Message.Encryption) {
      navigator.clipboard.writeText(this.encryptionResultValue)
      this.#notificationService.showInfo(Message.EncryptedTextCopiedToClipboard)
    } else {
      navigator.clipboard.writeText(this.decryptionResultValue)
      this.#notificationService.showInfo(Message.DecryptedTextCopiedToClipboard)
    }
  }

  pasteFromClipboard(type: string) {
    if (type === Message.Encryption) {
      navigator.clipboard.readText().then(clipText => {
        this.encryptionInputValue = clipText
        this.#notificationService.showInfo(Message.EncryptedTextPastedFromClipboard)
      })
    } else {
      navigator.clipboard.readText().then(clipText => {
        this.decryptionInputValue = clipText
        this.#notificationService.showInfo(Message.DecryptedTextPastedFromClipboard)
      })
    }
  }

  clearValue(type: string) {
    if (type === Message.Encryption) {
      this.encryptionInputValue = ''
      this.encryptionResultValue = ''
      this.#notificationService.showInfo(Message.EncryptedDataCleared)
    } else {
      this.decryptionInputValue = ''
      this.decryptionResultValue = ''
      this.#notificationService.showInfo(Message.DecryptedDataCleared)
    }
  }
}
