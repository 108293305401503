import { Component, OnInit, inject } from '@angular/core'
import { BaseComponent } from '../../../common/components/base.component'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { AuthService } from '../../../common/services/auth.service'
import { ActivatedRoute, Router } from '@angular/router'
import { UserStateService } from '../../../common/store/state/user/user.state.service'
import { ForgotPasswordResp } from '../../../common/models/ForgotPassword'
import { finalize } from 'rxjs'
import { SpinnerService } from '../../../common/services/spinner.service'
import { HttpErrorResponse } from '@angular/common/http'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {

  #authService = inject(AuthService)
  #router = inject(Router)
  #userStateService = inject(UserStateService)
  #spinnerService = inject(SpinnerService)
  #activatedRoute = inject(ActivatedRoute)

  forgotPasswordForm: FormGroup
  passwordLabel: string
  errorMessage: string

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.passwordLabel = this.#activatedRoute.snapshot.data['label']
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    })
  }

  async onContinue() {
    this.#spinnerService.setLoading(true, 'forgot-password')
    this.subscriptions.add(this.#authService.initForgotPassword(this.forgotPasswordForm.value)
      .pipe(finalize(() => {
        this.#spinnerService.setLoading(false, 'forgot-password')
      }))
      .subscribe({
        next: (resp: ForgotPasswordResp) => {
          this.#userStateService.setUnauthorizedUser(resp)
          this.#router.navigate(['/forgot-password/passcode'])
        },
        error: (httpErrorResp: HttpErrorResponse) => {
          console.log(httpErrorResp)
          this.#router.navigate(['/forgot-password/passcode'])
        }
      }))
  }

}
