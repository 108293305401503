<p-tabView>
    <p-tabPanel header="Encrypt">
        <p-card>
            <div class="flex flex-column gap-3">
                <label for="token" style="word-wrap: break-word;"><b>Current token: </b>{{token}}</label>
                <label for="aes key"><b>Current aes key: </b>{{aesKey}}</label>
                <label for="session"><b>Current Session: </b>{{sessionId}}</label>
                <p-button (click)="pasteFromClipboard('encryption')" label="Paste"></p-button>
                <textarea class="w-full" rows="5" cols="30" pInputTextarea
                    [(ngModel)]="encryptionInputValue"></textarea>
                <span>
                    <p-button icon="pi pi-lock" (click)="encryptData()" label="Encrypt"
                        [disabled]="!encryptionInputValue"></p-button>
                    <p-button class="ml-2" icon="pi pi-times" (click)="clearValue('encryption')" label="Clear"
                        [hidden]="!encryptionInputValue"></p-button>
                </span>
                <textarea class="w-full" rows="5" cols="30" pInputTextarea [(ngModel)]="encryptionResultValue" readonly
                    [hidden]="!encryptionResultValue"></textarea>
                <p-button icon="pi pi-copy" (click)="copyToClipboard('encryption')" label="Click to Copy"
                    [hidden]="!encryptionResultValue"></p-button>
            </div>
        </p-card>
    </p-tabPanel>
    <p-tabPanel header="Decrypt">
        <p-card>
            <div class="flex flex-column gap-3">
                <label for="token" style="word-wrap: break-word;"><b>Current token: </b>{{token}}</label>
                <label for="aes key"><b>Current aes key: </b>{{aesKey}}</label>
                <label for="session"><b>Current Session: </b>{{sessionId}}</label>
                <p-button (click)="pasteFromClipboard('decryption')" label="Paste"></p-button>
                <textarea class="w-full" rows="5" cols="30" pInputTextarea
                    [(ngModel)]="decryptionInputValue"></textarea>
                <span>
                    <p-button icon="pi pi-unlock" (click)="decryptData()" label="Decrypt"
                        [disabled]="!decryptionInputValue"></p-button>
                    <p-button class="ml-2" icon="pi pi-times" (click)="clearValue('decryption')" label="Clear"
                        [hidden]="!decryptionInputValue"></p-button>
                </span>
                <textarea class="w-full" rows="5" cols="30" pInputTextarea [(ngModel)]="decryptionResultValue" readonly
                    [hidden]="!decryptionResultValue"></textarea>
                <p-button icon="pi pi-copy" (click)="copyToClipboard('decryption')" label="Click to Copy"
                    [hidden]="!decryptionResultValue"></p-button>
            </div>
        </p-card>
    </p-tabPanel>
</p-tabView>