import { Component, OnInit, inject } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { BaseComponent } from '../../../../common/components/base.component'
import { UserStateService } from '../../../../common/store/state/user/user.state.service'
import { finalize } from 'rxjs'
import { CollectionType } from '../../../../common/enums/mfa.enum'
import { SpinnerService } from '../../../../common/services/spinner.service'
import { MfaService } from '../../../../common/services/mfa/mfa.service'

@Component({
  selector: 'app-mfa-selection',
  templateUrl: './mfa-selection.component.html',
  styleUrl: './mfa-selection.component.scss'
})
export class MfaSelectionComponent extends BaseComponent implements OnInit {

  #userStateService = inject(UserStateService)
  #router = inject(Router)
  #spinnerService = inject(SpinnerService)
  #mfaService = inject(MfaService)

  selectionForm: FormGroup
  errorMessage: string
  devicesTypes = ['Email']

  #mfaSpinnerId = 'mfa-spinner'

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.selectionForm = new FormGroup({
      selectedDevice: new FormControl('Email', [Validators.required]),
    })
    const phone = this.#userStateService.getUser()?.MobilePhone
    if (phone) {
      this.devicesTypes.unshift('Phone')
      this.selectionForm.patchValue({ selectedDevice: 'Phone' })
    }
  }

  onContinue() {
    if (!this.selectionForm.valid) {
      return
    }
    this.#validateContact()
  }

  #validateContact() {
    const selectionType = this.selectionForm.value.selectedDevice.toLowerCase()
    const selectionValue = selectionType === CollectionType.email ? this.#userStateService.getUser()?.ContactEmail : this.#userStateService.getUser()?.MobilePhone
    this.#spinnerService.setLoading(true, this.#mfaSpinnerId)
    this.subscriptions.add(
      this.#mfaService.validateContact(selectionType, selectionValue)
        .pipe(finalize(() => {
          this.#spinnerService.setLoading(false, this.#mfaSpinnerId)
        }))
        .subscribe({
          next: () => {
            this.#router.navigate([`/mfa/verification/${selectionType}`])
          },
          error: () => {
          }
        }))
  }
}
