<app-unauthorized-header-layout header="Two-Factor Authentication"
    [subHeader]="subheader" [subHeader2]="subheader2"></app-unauthorized-header-layout>
<div class="text-color-secondary text-center">
    <p>
        <span class="font-light">{{subheader3}}</span>
    </p>
    <span class="font-light text-sm">The verification code is valid for 10 minutes
    </span>
</div>
@if (errorMessage) {
<div class="flex p-error justify-content-center mt-4 mb-2 text-xl">{{errorMessage}}</div>
}
<div class="flex justify-content-center flex-wrap w-full mt-3">
    <div class="surface-card w-saraplus-form border-round w-full sm:w-7 md:w-5 lg:4">
        <sp-form-wrapper [formGroup]="pinForm" (submit)="onValidate()">
            <div class="flex flex-column align-items-center">
                <div>
                    <div class="mb-3">
                        <p-inputOtp [autofocus]="true" formControlName="pin" [tabindex]="1" [integerOnly]="true" [length]="6"
                             class="w-full mb-1 justify-content-between"></p-inputOtp>
                        <sp-control-error [parameters]="['Code']" [control]="pinForm.controls['pin']"></sp-control-error>
                    </div>
                    <div class="flex justify-content-between mb-12rem">
                        <p-button icon="pi pi-chevron-left" label="Back" [link]="true"
                            styleClass="outline-none font-italic text-lg -ml-3" (click)="onBackClicked()"></p-button>
                        <button label="VALIDATE" pButton pRipple type="submit" [disabled]="!pinForm.valid" size="small">
                        </button>
                    </div>
                </div>
            </div>
        </sp-form-wrapper>
    </div>
</div>
<div class="flex flex-column align-items-center w-full h-8rem">
    <p-button label="Send another code" icon="pi pi-refresh" iconPos="right" [link]="true" styleClass="outline-none text-sm" (click)="sendPin()"></p-button>
    <p-button label="Still having trouble? Click here for help" [link]="true"
        styleClass="outline-none text-sm"></p-button>
</div>