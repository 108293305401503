import { Component, OnInit, OnDestroy, Input, inject } from '@angular/core'
import { interval, Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { DialogService } from 'primeng/dynamicdialog'
import { CountdownDialogComponent } from '../countdowndialog/countdowndialog.component'
import { CountdownTimerService } from '../../services/countdown-timer.service'
import { NotificationService } from '../../services/notification.service'

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styles: `.timer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000
}`,
})

export class CountdownTimerComponent implements OnInit, OnDestroy {
  countdown: number = 1200
  destroy$: Subject<void> = new Subject()
  Math = Math
  show: boolean = false
  subscription: Subscription = new Subscription
  @Input() showCountdown: boolean = true

  countdownService: CountdownTimerService = inject(CountdownTimerService)
  dialogService: DialogService = inject(DialogService)
  toastrSvc: NotificationService = inject(NotificationService)

  ngOnInit(): void {
    this.subscription.add(
      this.countdownService.startCountdown$.subscribe(() => {
        this.startCountdown()
        this.show = true
      })
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  resetCountdown(): void {
    this.toastrSvc.showInfo('Countdown has been reset to 20 minutes')
    this.show = true
    this.countdown = 1200
  }

  startCountdown(): void {
    interval(1000)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.countdown > 0) {
          this.countdown--
          if (this.countdown === 60) {
            this.openDialog()
          }
        } else {
          this.resetCountdown()
        }
      })
  }

  openDialog(): void {
    const ref = this.dialogService.open(CountdownDialogComponent, {
      header: 'Session Expiry Warning!',
      width: '300px',
      closable: false,
      style: { 'text-align': 'center' },
      breakpoints: {
        '1120px': '80vw',
        '575px': '90vw'
      }
    })

    ref.onClose.subscribe((result) => {
      if (result && result.refresh) {
        this.resetCountdown()
      }
    })
  }
}
