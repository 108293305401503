<ng-container *ngIf="!confirmed else securityQAConfirmed">
    <app-unauthorized-header-layout header="Security Questions"
        subHeader="SARA Plus requires you to further secure your account." subHeader2="Please choose a security question below to protect your account in the future."></app-unauthorized-header-layout>
        <br>
    @if (errorMessage) {
    <div class="flex p-error justify-content-center mt-4 mb-2 text-xl">{{errorMessage}}</div>
    }
    <div class="flex justify-content-center w-full mt-3">
        <div class="surface-card border-round w-full sm:w-7 md:w-6 lg:4">
            <sp-form-wrapper [formGroup]="securityQAForm" (submit)="onSubmit()">
                <div class="flex flex-column">
                    <span class="mb-3">
                        <p-dropdown styleClass="w-full" formControlName="question" [options]="lstQuestions$ | async" optionLabel="Question"
                            optionValue="QuestionId" placeholder="Please select your question" />
                        <sp-control-error [parameters]="['Question']"
                            [control]="securityQAForm.controls['question']"></sp-control-error>
                    </span>
                    <span class="mb-4">
                        <input type="text" placeholder="Answer" pInputText formControlName="answer"
                            class="w-full mb-1" />
                        <sp-control-error [parameters]="['Answer']"
                            [control]="securityQAForm.controls['answer']"></sp-control-error>
                    </span>
                    <div class="flex justify-content-center mb-5">
                        <button label="SUBMIT" pButton pRipple type="submit" [disabled]="!securityQAForm.valid"
                            size="small">
                        </button>
                    </div>
                </div>
            </sp-form-wrapper>
        </div>
    </div>
    <div class="flex flex-column align-items-center w-full h-8rem mt-6">
        <p-button label="Need help? Click here" [link]="true" styleClass="outline-none text-sm"></p-button>
    </div>

</ng-container>
<ng-template #securityQAConfirmed>
    <app-unauthorized-header-layout header="Security question was successfully set up!"></app-unauthorized-header-layout>
    <div class="text-center">
        <p-button label="Continue"  icon="pi pi-chevron-right" iconPos="right" [link]="true" styleClass="outline-none ml-3"
            (onClick)="onContinueClick()"></p-button>
    </div>
</ng-template>