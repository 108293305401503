import { Component, OnInit, inject } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { UserStateService } from '../../../../common/store/state/user/user.state.service'
import { SpinnerService } from '../../../../common/services/spinner.service'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BaseComponent } from '../../../../common/components/base.component'
import { MFAValidateContactModel, UserDetails } from '../../../../common/models/Login'
import { finalize } from 'rxjs'
import { MfaService } from '../../../../common/services/mfa/mfa.service'
import { CollectionType } from '../../../../common/enums/mfa.enum'
import { AuthService } from '../../../../common/services/auth.service'

@Component({
  selector: 'app-mfa-collection',
  templateUrl: './mfa-collection.component.html',
  styleUrl: './mfa-collection.component.scss'
})
export class MfaCollectionComponent extends BaseComponent implements OnInit {

  #router = inject(Router)
  #spinnerService = inject(SpinnerService)
  #activatedRoute = inject(ActivatedRoute)
  #userStateService = inject(UserStateService)
  #authService = inject(AuthService)
  #mfaService = inject(MfaService)
  userInfo: UserDetails
  mfaCollectionForm: FormGroup
  errorMessage: string
  collectionType: CollectionType
  collectionTypeLabel: 'Email' | 'Phone' = 'Email'
  #mfaSpinnerId = 'mfa-spinner'
  collectionTypeEnum = CollectionType

  async onContinue() {
    if (this.mfaCollectionForm.valid) {
      this.#validateContact()
    }
  }


  ngOnInit(): void {
    this.userInfo = this.#userStateService.getUser()
    this.collectionType = this.#activatedRoute.snapshot.params['deviceType']
    this.collectionTypeLabel = this.collectionType === CollectionType.email ? 'Email' : 'Phone'
    this.setupForm()
  }

  setupForm() {
    this.mfaCollectionForm = new FormGroup({
      email: new FormControl('', []),
      phone: new FormControl('', [])
    })
    if (this.collectionType === CollectionType.email) {
      this.mfaCollectionForm.controls[this.collectionType].addValidators([Validators.required])
    } else {
      this.mfaCollectionForm.controls[this.collectionType].addValidators([Validators.required, Validators.pattern('[0-9-()]*')])
    }
    this.mfaCollectionForm.updateValueAndValidity()
  }

  #invalidErrorMessage() {
    return `Invalid ${this.collectionTypeLabel}`
  }


  #validateContact() {
    const collectionValue = this.collectionType === CollectionType.email ? this.mfaCollectionForm.value.email : this.mfaCollectionForm.value.phone
    this.#spinnerService.setLoading(true, this.#mfaSpinnerId)
    this.subscriptions.add(
      this.#mfaService.validateContact(this.collectionType, collectionValue)
        .pipe(finalize(() => {
          this.#spinnerService.setLoading(false, this.#mfaSpinnerId)
        }))
        .subscribe({
          next: (resp: MFAValidateContactModel) => {
            this.#userStateService.setUser({ ...this.userInfo, ContactEmail: resp.ContactEmail, MobilePhone: resp.MobilePhone })
            this.#router.navigate([`/mfa/verification/${this.collectionType}`])
          },
          error: () => {
            this.errorMessage = this.#invalidErrorMessage()
          }
        }))
  }

  onSkipThisStep() {
    if (this.userInfo?.ContactEmail) {
      this.subscriptions.add(this.#authService.getWelcomeInfo().subscribe(() => {
        this.#router.navigate(['/welcome']);
      }))
    }
  }

}
