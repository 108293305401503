<app-unauthorized-header-layout header="Two-Factor Authentication" subHeader=" {{collectionTypeLabel}} Collection" [subHeader2]="collectionType === collectionTypeEnum.phone ? 'Provide a phone number below as an alternative to receiving the code (optional).' : 'SARA Plus requires you to protect your account with 2FA. &lt;br&gt; The email provided below will be used to receive one time passcode.'"></app-unauthorized-header-layout>

<div class="text-color-secondary mt-4 flex justify-content-center mb-3">
</div>
@if (errorMessage) {
<div class="flex p-error justify-content-center my-1 text-xl">{{errorMessage}}</div>
}
<div class="flex justify-content-center flex-wrap w-full">
    <div class="surface-card w-saraplus-form border-round w-full sm:w-7 md:w-5 lg:4">
        <div>
            <sp-form-wrapper [formGroup]="mfaCollectionForm" (submit)="onContinue()">
                <div class="flex flex-column">
                    <span [ngClass]="{'mb-1': !mfaCollectionForm.valid, 'mb-4': mfaCollectionForm['untouched']}">
                        <ng-container
                            [ngTemplateOutlet]="collectionType === collectionTypeEnum.email ? emailCollectionTemplate : phoneCollectionTemplate">
                        </ng-container>
                    </span>
                    <div class="flex justify-content-end mb-5">
                        <button label="CONTINUE" pButton pRipple type="submit" [disabled]="!mfaCollectionForm.valid"
                            size="small">
                        </button>
                    </div>
                </div>
            </sp-form-wrapper>
        </div>
    </div>
</div>

@if (collectionType === 'phone') {
    <div class="text-color-secondary flex justify-content-center mb-3">
        <p-button label="Skip this step" icon="pi pi-chevron-right" iconPos="right" [link]="true"
        styleClass="outline-none font-italic text-lg -ml-3" (click)="onSkipThisStep()"></p-button>
    </div>
}

<ng-template #emailCollectionTemplate>
    <ng-container [formGroup]="mfaCollectionForm">
        <input type="text" placeholder="Enter email" pInputText formControlName="email" class="w-full mb-1" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}$" />
        <sp-control-error [parameters]="['Email']" [control]="mfaCollectionForm.controls['email']"></sp-control-error>
        <small *ngIf="mfaCollectionForm.controls['email'].errors?.['pattern']" class="ml-2 p-error">
            <i>Please enter a valid email address</i>
        </small>
    </ng-container>
</ng-template>

<ng-template #phoneCollectionTemplate>
    <ng-container [formGroup]="mfaCollectionForm">
        <p-inputMask [autoClear]="false" [autofocus]="true" mask="(999)999-9999" formControlName="phone" placeholder="(999)999-9999"
            styleClass="w-full mb-1"></p-inputMask>
        <sp-control-error [parameters]="['Phone']" [control]="mfaCollectionForm.controls['phone']"></sp-control-error>
    </ng-container>
</ng-template>