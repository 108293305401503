import { Injectable, inject } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { AppStateService } from '../store/state/app/app.state.service'
import { EncryptDecryptService } from '../services/encrypt-decrypt.service'
import { EnvironmentService } from '../services/environment.service'
import { skipInterceptionUrls } from '../constants/skip-url'

@Injectable({
  providedIn: 'root'
})
export class PayloadEncryptionInterceptor implements HttpInterceptor {

  #appStateService = inject(AppStateService)
  #encryptDecryptService = inject(EncryptDecryptService)
  #environmentService = inject(EnvironmentService)

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.#encrypt(request))
  }

  #encrypt(request: HttpRequest<unknown>): HttpRequest<unknown> {
    if (skipInterceptionUrls.some(item => request.url.includes(item))) {
      return request
    }

    if (this.#environmentService.getIgnoreEncryption()) {
      return request.clone({ headers: request.headers.set('Content-Type', 'application/json') })
    }

    const clientAesKey = this.#appStateService.getSession()?.ClientAesKey
    if ((request.method === 'POST' || request.method === 'PUT') && clientAesKey && request.body) {

      const encrypted = this.#encryptDecryptService.encrypt(request.body, clientAesKey)
      return request.clone({ body: JSON.stringify(encrypted), headers: request.headers.set('Content-Type', 'application/json') })
    }
    return request
  }

}