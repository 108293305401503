import { Component, inject } from '@angular/core'
import { DynamicDialogRef } from 'primeng/dynamicdialog'

@Component({
  selector: 'app-countdown-dialog',
  templateUrl: './countdowndialog.component.html',
})
export class CountdownDialogComponent {
  dialogMessage: string = 'The session expires in 60 seconds. Click Refresh to continue'

  ref: DynamicDialogRef = inject(DynamicDialogRef)

  refreshCountdown(): void {
    this.ref.close({ refresh: true })
  }

  closeDialog(): void {
    this.ref.close()
  }
}
