import { Component, OnInit, inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { finalize } from 'rxjs'
import { BaseComponent } from '../../../../common/components/base.component'
import { Message } from '../../../../common/enums/message.enum'
import { CollectionType } from '../../../../common/enums/mfa.enum'
import { LoginFlow, UserDetails } from '../../../../common/models/Login'
import { MfaService } from '../../../../common/services/mfa/mfa.service'
import { NotificationService } from '../../../../common/services/notification.service'
import { SpinnerService } from '../../../../common/services/spinner.service'
import { LoginFlowStateService } from '../../../../common/store/state/login-flow/login-flow.state.service'
import { UserStateService } from '../../../../common/store/state/user/user.state.service'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { SessionTypeEnum } from '../../../../common/models/SessionTypeEnum'

@Component({
  selector: 'app-mfa-verification-dialog',
  templateUrl: './mfa-verification-dialog.component.html',
  styleUrl: './mfa-verification-dialog.component.scss'
})
export class MfaVerificationDialogComponent extends BaseComponent implements OnInit {

  header = 'Two-Factor Authentication'
  subheader = ''
  subheader2 = 'Please enter the six digit code sent to your selected method'
  subheader3 = ''

  #spinnerService = inject(SpinnerService)
  #userStateService = inject(UserStateService)
  #loginFlowStateService = inject(LoginFlowStateService)
  #mfaService = inject(MfaService)
  #notificationService = inject(NotificationService)

  #mfaSpinnerId = 'mfa-spinner'
  pinForm: FormGroup
  errorMessage: string
  collectionType: CollectionType
  userInfo: UserDetails
  loginFlow: LoginFlow
  contact: string

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    super()
    this.collectionType = config.data.collectionType as CollectionType;
  }

  ngOnInit(): void {
    this.pinForm = new FormGroup({
      pin: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6,6}')]),
    })

    this.userInfo = this.#userStateService.getUser()
    this.loginFlow = this.#loginFlowStateService.getLoginFlow()
  }

  onValidate() {
    this.#spinnerService.setLoading(true, this.#mfaSpinnerId)
    this.subscriptions.add(
      this.#mfaService.validatePin(this.pinForm.value.pin, SessionTypeEnum.VALIDATE)
        .pipe(finalize(() => {
          this.#spinnerService.setLoading(false, this.#mfaSpinnerId)
        }))
        .subscribe({
          next: () => {
            this.ref.close(true);
          },
          error: (err) => {
            if(err && err.error) this.errorMessage = err.error;
            else this.errorMessage = 'Please try again';
          }
        })
      )
  }

  sendPin() {
    this.#spinnerService.setLoading(true, this.#mfaSpinnerId)
    this.subscriptions.add(
      this.#mfaService.validate(SessionTypeEnum.VALIDATE)
        .pipe(finalize(() => {
          this.#spinnerService.setLoading(false, this.#mfaSpinnerId)
        }))
        .subscribe(
          {
            next: () => {
              this.#notificationService.showSuccess(Message.PinResentSuccess)
            },
            error: () => {
              this.errorMessage = 'Invalid Email or Phone'
            }
          }
        )
      )
  }
}
