import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class CountdownTimerService {
  private startCountdownSource = new Subject<void>()
  startCountdown$ = this.startCountdownSource.asObservable()

  private showCountdownSource = new Subject<boolean>()
  showCountdown$ = this.showCountdownSource.asObservable()

  startCountdown() {
    this.startCountdownSource.next()
  }

  showCountdown(show: boolean) {
    this.showCountdownSource.next(show)
  }
}